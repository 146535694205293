@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
/* ===========
   Components List

   - Buttons
   - Panels
   - Portlets
   - Checkbox and radio
   - Progressbars
   - Carousel
   - Tables
   - Form Elements
   - Calendar
   - Widgets
 =============*/
/* ===========
   Buttons
 =============*/
.btn {
  border-radius: 2px;
  padding: 6px 14px;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 10px 16px !important;
  font-size: 16px;
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 5px 10px !important;
}
.btn-group-xs > .btn,
.btn-xs {
  padding: 1px 5px !important;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: 0px;
}
.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger,
.btn-inverse,
.btn-purple,
.btn-pink {
  color: #ffffff;
}
.btn-default {
  background-color: #dae6ec;
  border-color: #dae6ec;
}
.btn-default:focus {
  background-color: #dae6ec;
  border-color: #C2CED4;
}
.btn-default:hover {
  background-color: #dae6ec;
  border-color: #C2CED4;
}
.btn-default:active {
  background-color: #dae6ec;
  border-color: #C2CED4;
}
.btn-default.active,
.btn-default:active,
.open > .dropdown-toggle.btn-default {
  background-color: #dae6ec !important;
  border-color: #C2CED4 !important;
  color:#555252;
}
.btn-primary {
  background-color: #fff;
  border: 1px solid #3bafda;
  color:#555252;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open > .dropdown-toggle.btn-primary {
  background-color: #fff ;
  border: 1px solid #28a5d4 ;
  color:#555252;
}
.btn-success {
  background-color: #00b19d !important;
  border: 1px solid #00b19d !important;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus,
.btn-success:active,
.btn-success:focus,
.btn-success:hover,
.open > .dropdown-toggle.btn-success {
  background-color: #009886 !important;
  border: 1px solid #009886 !important;
}
.btn-info {
  background-color: #3ddcf7 !important;
  border: 1px solid #3ddcf7 !important;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus,
.btn-info:active,
.btn-info:focus,
.btn-info:hover,
.open > .dropdown-toggle.btn-info {
  background-color: #25d8f6 !important;
  border: 1px solid #25d8f6 !important;
}
.btn-warning {
  background-color: #ffaa00 !important;
  border: 1px solid #ffaa00 !important;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus,
.btn-warning:active,
.btn-warning:focus,
.btn-warning:hover,
.open > .dropdown-toggle.btn-warning {
  background-color: #e69900 !important;
  border: 1px solid #e69900 !important;
}
.btn-danger {
  background-color: #ef5350 !important;
  border: 1px solid #ef5350 !important;
}
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.btn-danger.active,
.btn-danger.focus,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.open > .dropdown-toggle.btn-danger {
  background-color: #ed3c39 !important;
  border: 1px solid #ed3c39 !important;
}
.btn-inverse {
  background-color: #4c5667 !important;
  border: 1px solid #4c5667 !important;
}
.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse {
  background-color: #414a58 !important;
  border: 1px solid #414a58 !important;
}
.btn-purple {
  background-color: #7266ba !important;
  border: 1px solid #7266ba !important;
}
.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active {
  background-color: #6254b2 !important;
  border: 1px solid #6254b2 !important;
}
.btn-pink {
  background-color: #f76397 !important;
  border: 1px solid #f76397 !important;
}
.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active {
  background-color: #f64b87 !important;
  border: 1px solid #f64b87 !important;
}
.btn-custom {
  border-bottom: 3px solid transparent;
}
.btn-custom.btn-default {
  background-color: #dae6ec;
  border-bottom: 2px solid #a4b6bf !important;
}
.btn-custom.btn-primary {
  border-bottom: 2px solid #2494be !important;
}
.btn-custom.btn-success {
  border-bottom: 2px solid #007e70 !important;
}
.btn-custom.btn-info {
  border-bottom: 2px solid #08aac6 !important;
}
.btn-custom.btn-warning {
  border-bottom: 2px solid #cc8800 !important;
}
.btn-custom.btn-danger {
  border-bottom: 2px solid #c71612 !important;
}
.btn-custom.btn-inverse {
  border-bottom: 2px solid #21252c !important;
}
.btn-custom.btn-purple {
  border-bottom: 2px solid #443a80 !important;
}
.btn-custom.btn-pink {
  border-bottom: 2px solid #e80c59 !important;
}
.btn-rounded {
  border-radius: 2em;
  padding: 6px 18px;
}
.fileupload {
  overflow: hidden;
  position: relative;
}
.fileupload input.upload {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 20px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}
/* ===========
   Panels
 =============*/
.panel {
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  border: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.panel .panel-body {
  padding: 20px;
}
.panel .panel-body p {
  margin: 0px;
}
.panel .panel-body p + p {
  margin-top: 15px;
}
.panel-heading {
  border-radius: 0;
  border: none !important;
  padding: 10px 20px;
}
.panel-default > .panel-heading {
  background-color: #fafafa;
  border-bottom: none;
  color: #797979;
}
.panel-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.panel-sub-title {
  margin-bottom: 3px;
  color: #CEE4FD;
  margin-top: -3px;
}
.panel-footer {
  background: #fafafa;
  border-top: 0px;
}
.panel-default .panel-sub-title {
  color: inherit;
}
.panel-color .panel-title {
  color: #ffffff;
}
.panel-primary > .panel-heading {
  background-color: #3bafda;
}
.panel-success > .panel-heading {
  background-color: #00b19d;
}
.panel-info > .panel-heading {
  background-color: #3ddcf7;
}
.panel-warning > .panel-heading {
  background-color: #ffaa00;
}
.panel-danger > .panel-heading {
  background-color: #ef5350;
}
.panel-purple > .panel-heading {
  background-color: #7266ba;
}
.panel-pink > .panel-heading {
  background-color: #f76397;
}
.panel-inverse > .panel-heading {
  background-color: #4c5667;
}
.panel-border {
  border-radius: 3px;
}
.panel-border .panel-heading {
  background-color: #ffffff;
  border-top: 3px solid #ccc !important;
  border-radius: 3px;
  padding: 10px 20px 0px;
}
.panel-border .panel-body {
  padding: 15px 20px 20px 20px;
}
.panel-border.panel-primary .panel-heading {
  border-color: #3bafda !important;
  color: #3bafda !important;
}
.panel-border.panel-success .panel-heading {
  border-color: #00b19d !important;
  color: #00b19d !important;
}
.panel-border.panel-info .panel-heading {
  border-color: #3ddcf7 !important;
  color: #3ddcf7 !important;
}
.panel-border.panel-warning .panel-heading {
  border-color: #ffaa00 !important;
  color: #ffaa00 !important;
}
.panel-border.panel-danger .panel-heading {
  border-color: #ef5350 !important;
  color: #ef5350 !important;
}
.panel-border.panel-purple .panel-heading {
  border-color: #7266ba !important;
  color: #7266ba !important;
}
.panel-border.panel-pink .panel-heading {
  border-color: #f76397 !important;
  color: #f76397 !important;
}
.panel-border.panel-inverse .panel-heading {
  border-color: #4c5667 !important;
  color: #4c5667 !important;
}
.panel-group .panel .panel-heading a[data-toggle=collapse].collapsed:before {
  content: '\f067';
}
.panel-group .panel .panel-heading .accordion-toggle.collapsed:before {
  content: '\f067';
}
.panel-group .panel .panel-heading a[data-toggle=collapse] {
  display: block;
}
.panel-group .panel .panel-heading a[data-toggle=collapse]:before {
  content: '\f068';
  display: block;
  float: right;
  font-family: 'FontAwesome';
  font-size: 14px;
  text-align: right;
  width: 25px;
}
.panel-group .panel .panel-heading .accordion-toggle {
  display: block;
}
.panel-group .panel .panel-heading .accordion-toggle:before {
  content: '\f068';
  display: block;
  float: right;
  font-family: 'FontAwesome';
  font-size: 14px;
  text-align: right;
  width: 25px;
}
.panel-group .panel .panel-heading + .panel-collapse .panel-body {
  border-top: none;
}
.panel-group .panel-heading {
  padding: 12px 26px;
}
.panel-group.panel-group-joined .panel + .panel {
  border-top: 1px solid #eeeeee;
  margin-top: 0;
}
.panel-group-joined .panel-group .panel + .panel {
  border-top: 1px solid #eeeeee;
  margin-top: 0;
}
/* ===========
   Portlets
 =============*/
.portlet {
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.4s;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: all 0.4s;
}
.portlet .portlet-heading {
  border-radius: 3px;
  color: #ffffff;
  padding: 12px 20px;
}
.portlet .portlet-heading .portlet-title {
  color: #ffffff;
  float: left;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.portlet .portlet-heading .portlet-widgets {
  display: inline-block;
  float: right;
  font-size: 15px;
  line-height: 30px;
  padding-left: 15px;
  position: relative;
  text-align: right;
}
.portlet .portlet-heading .portlet-widgets .divider {
  margin: 0 5px;
}
.portlet .portlet-heading a {
  color: #999999;
}
.portlet .portlet-body {
  -moz-border-radius-bottomleft: 5px;
  -moz-border-radius-bottomright: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  background: #ffffff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 15px;
}
.portlet-default .portlet-title {
  color: #797979 !important;
}
.portlet .portlet-heading .portlet-widgets .collapsed .ion-minus-round:before {
  content: "\f217" !important;
}
.portlet .portlet-heading.bg-purple a,
.portlet .portlet-heading.bg-info a,
.portlet .portlet-heading.bg-success a,
.portlet .portlet-heading.bg-primary a,
.portlet .portlet-heading.bg-danger a,
.portlet .portlet-heading.bg-warning a,
.portlet .portlet-heading.bg-inverse a,
.portlet .portlet-heading.bg-pink a {
  color: #ffffff;
}
.panel-disabled {
  background: rgba(243, 242, 241, 0.5);
  cursor: progress;
  bottom: 15px;
  left: 0px;
  position: absolute;
  right: -5px;
  top: 0;
}
.loader-1 {
  -moz-animation: loaderAnimate 1000ms linear infinite;
  -o-animation: loaderAnimate 1000ms linear infinite;
  -webkit-animation: loaderAnimate 1000ms linear infinite;
  animation: loaderAnimate 1000ms linear infinite;
  clip: rect(0, 30px, 30px, 15px);
  height: 30px;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  position: absolute;
  top: 50%;
  width: 30px;
}
.loader-1:after {
  -moz-animation: loaderAnimate2 1000ms ease-in-out infinite;
  -o-animation: loaderAnimate2 1000ms ease-in-out infinite;
  -webkit-animation: loaderAnimate2 1000ms ease-in-out infinite;
  animation: loaderAnimate2 1000ms ease-in-out infinite;
  border-radius: 50%;
  clip: rect(0, 30px, 30px, 15px);
  content: '';
  height: 30px;
  position: absolute;
  width: 30px;
}
/* ===========
   Checkbox and Radio
 =============*/
.checkbox {
  padding-left: 20px;
}
.checkbox label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
}
.checkbox label::before {
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #cccccc;
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 17px;
  outline: none !important;
}
.checkbox label::after {
  color: #555555;
  display: inline-block;
  font-size: 11px;
  height: 16px;
  left: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  position: absolute;
  top: 0;
  width: 16px;
}
.checkbox input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}
.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}
.checkbox input[type="checkbox"]:focus + label::before {
  outline-offset: -2px;
  outline: none;
}
.checkbox input[type="checkbox"]:checked + label::after {
  content: "\f00c";
  font-family: 'FontAwesome';
}
.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}
.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}
.checkbox.checkbox-inline {
  margin-top: 0;
}
.checkbox.checkbox-single label {
  height: 17px;
}
.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #3bafda;
  border-color: #3bafda;
}
.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #ef5350;
  border-color: #ef5350;
}
.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #3ddcf7;
  border-color: #3ddcf7;
}
.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #ffaa00;
  border-color: #ffaa00;
}
.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #00b19d;
  border-color: #00b19d;
}
.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-purple input[type="checkbox"]:checked + label::before {
  background-color: #7266ba;
  border-color: #7266ba;
}
.checkbox-purple input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-pink input[type="checkbox"]:checked + label::before {
  background-color: #f76397;
  border-color: #f76397;
}
.checkbox-pink input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-inverse input[type="checkbox"]:checked + label::before {
  background-color: #4c5667;
  border-color: #4c5667;
}
.checkbox-inverse input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.radio {
  padding-left: 20px;
}
.radio label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
}
.radio label::before {
  -o-transition: border 0.5s ease-in-out;
  -webkit-transition: border 0.5s ease-in-out;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #cccccc;
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  outline: none !important;
  position: absolute;
  transition: border 0.5s ease-in-out;
  width: 17px;
}
.radio label::after {
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -webkit-transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  background-color: #555555;
  border-radius: 50%;
  content: " ";
  display: inline-block;
  height: 11px;
  left: 3px;
  margin-left: -20px;
  position: absolute;
  top: 3px;
  transform: scale(0, 0);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  width: 11px;
}
.radio input[type="radio"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}
.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}
.radio input[type="radio"]:focus + label::before {
  outline-offset: -2px;
  outline: 5px auto -webkit-focus-ring-color;
  outline: thin dotted;
}
.radio input[type="radio"]:checked + label::after {
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}
.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}
.radio.radio-inline {
  margin-top: 0;
}
.radio.radio-single label {
  height: 17px;
}
.radio-primary input[type="radio"] + label::after {
  background-color: #3bafda;
}
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #3bafda;
}
.radio-primary input[type="radio"]:checked + label::after {
  background-color: #3bafda;
}
.radio-danger input[type="radio"] + label::after {
  background-color: #ef5350;
}
.radio-danger input[type="radio"]:checked + label::before {
  border-color: #ef5350;
}
.radio-danger input[type="radio"]:checked + label::after {
  background-color: #ef5350;
}
.radio-info input[type="radio"] + label::after {
  background-color: #3ddcf7;
}
.radio-info input[type="radio"]:checked + label::before {
  border-color: #3ddcf7;
}
.radio-info input[type="radio"]:checked + label::after {
  background-color: #3ddcf7;
}
.radio-warning input[type="radio"] + label::after {
  background-color: #ffaa00;
}
.radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffaa00;
}
.radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffaa00;
}
.radio-success input[type="radio"] + label::after {
  background-color: #00b19d;
}
.radio-success input[type="radio"]:checked + label::before {
  border-color: #00b19d;
}
.radio-success input[type="radio"]:checked + label::after {
  background-color: #00b19d;
}
.radio-purple input[type="radio"] + label::after {
  background-color: #7266ba;
}
.radio-purple input[type="radio"]:checked + label::before {
  border-color: #7266ba;
}
.radio-purple input[type="radio"]:checked + label::after {
  background-color: #7266ba;
}
.radio-pink input[type="radio"] + label::after {
  background-color: #f76397;
}
.radio-pink input[type="radio"]:checked + label::before {
  border-color: #f76397;
}
.radio-pink input[type="radio"]:checked + label::after {
  background-color: #f76397;
}
.radio-inverse input[type="radio"] + label::after {
  background-color: #4c5667;
}
.radio-inverse input[type="radio"]:checked + label::before {
  border-color: #4c5667;
}
.radio-inverse input[type="radio"]:checked + label::after {
  background-color: #4c5667;
}
/* ===========
   Progressbars
 =============*/
.progress {
  -webkit-box-shadow: none !important;
  background-color: #f5f5f5;
  box-shadow: none !important;
  height: 10px;
  margin-bottom: 18px;
  overflow: hidden;
}
.progress-bar {
  box-shadow: none;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
}
.progress.progress-sm {
  height: 5px !important;
}
.progress.progress-sm .progress-bar {
  font-size: 8px;
  line-height: 5px;
}
.progress.progress-md {
  height: 15px !important;
}
.progress.progress-md .progress-bar {
  font-size: 10.8px;
  line-height: 14.4px;
}
.progress.progress-lg {
  height: 20px !important;
}
.progress.progress-lg .progress-bar {
  font-size: 12px;
  line-height: 20px;
}
.progress-bar-primary {
  background-color: #3bafda;
}
.progress-bar-success {
  background-color: #00b19d;
}
.progress-bar-info {
  background-color: #3ddcf7;
}
.progress-bar-warning {
  background-color: #ffaa00;
}
.progress-bar-danger {
  background-color: #ef5350;
}
.progress-bar-inverse {
  background-color: #4c5667;
}
.progress-bar-purple {
  background-color: #7266ba;
}
.progress-bar-pink {
  background-color: #f76397;
}
.progress-animated {
  -webkit-animation-duration: 5s;
  -webkit-animation-name: animationProgress;
  -webkit-transition: 5s all;
  animation-duration: 5s;
  animation-name: animationProgress;
  transition: 5s all;
}
/* ===========
   Carousel
 =============*/
.carousel-control {
  width: 10%;
}
.carousel-control span {
  position: absolute;
  top: 50%;
  /* pushes the icon in the middle of the height */
  z-index: 5;
  display: inline-block;
  font-size: 30px;
}
.carousel-indicators li {
  border: 2px solid #ffffff;
}
/* ===========
   Tables
 =============*/
.table {
  margin-bottom: 10px;
}
tbody {
  color: #797979;
}
th {
  color: #666666;
  font-size: 15px;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border-top: 1px solid #f3f3f3;
}
.table > thead > tr > th {
  border-bottom: 2px solid #f3f3f3;
}
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > thead > tr > td.success,
.table > thead > tr > th.success {
  background-color: rgba(0, 177, 157, 0.15);
}
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > thead > tr > td.info,
.table > thead > tr > th.info {
  background-color: rgba(61, 220, 247, 0.15);
}
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > thead > tr > td.warning,
.table > thead > tr > th.warning {
  background-color: rgba(255, 170, 0, 0.15);
}
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > thead > tr > td.danger,
.table > thead > tr > th.danger {
  background-color: rgba(239, 83, 80, 0.15);
}
table.focus-on tbody tr.focused th {
  background-color: #3bafda;
  color: #ffffff;
}
table.focus-on tbody tr.focused td {
  background-color: #3bafda;
  color: #ffffff;
}
.table-rep-plugin {
  /* Table Responsive */
}
.table-rep-plugin tbody th {
  font-size: 14px;
  font-weight: normal;
}
.table-rep-plugin .sticky-table-header.fixed-solution {
  top: 70px !important;
}
.modal-block {
  background: transparent;
  margin: 40px auto;
  max-width: 600px;
  padding: 0;
  position: relative;
  text-align: left;
}
/* Data table */
#datatable-editable .actions a {
  padding: 5px;
}
#datatable-editable .form-control {
  background-color: #ffffff;
  width: 100%;
}
#datatable-editable .fa-trash-o {
  color: #ef5350;
}
#datatable-editable .fa-times {
  color: #ef5350;
}
#datatable-editable .fa-pencil {
  color: #29b6f6;
}
#datatable-editable .fa-save {
  color: #33b86c;
}
#datatable td {
  font-weight: normal;
}
div.dataTables_paginate ul.pagination {
  margin-top: 30px;
}
div.dataTables_info {
  padding-top: 38px;
}
.dt-buttons {
  float: left;
}
div#datatable-buttons_info {
  float: left;
}
/* Fixed Header */
.fixedHeader-floating {
  top: 70px !important;
}
/* Key table */
table.dataTable th.focus,
table.dataTable td.focus {
  outline: 3px solid #3bafda !important;
  outline-offset: -1px;
}
.table-rep-plugin .table-responsive {
  border: none !important;
}
.table-rep-plugin tbody th {
  font-size: 14px;
  font-weight: normal;
}
.table-rep-plugin .checkbox-row {
  padding-left: 40px;
}
.table-rep-plugin .checkbox-row label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
}
.table-rep-plugin .checkbox-row label::before {
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #cccccc;
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 17px;
  outline: none !important;
}
.table-rep-plugin .checkbox-row label::after {
  color: #555555;
  display: inline-block;
  font-size: 11px;
  height: 16px;
  left: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  position: absolute;
  top: -1px;
  width: 16px;
}
.table-rep-plugin .checkbox-row input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:focus + label::before {
  outline-offset: -2px;
  outline: none;
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::after {
  content: "\f00c";
  font-family: 'FontAwesome';
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::before {
  background-color: #3bafda;
  border-color: #3bafda;
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
/* Tablesaw */
.tablesaw thead {
  background: #f5f5f5;
  background-image: none;
  border: none;
}
.tablesaw thead th {
  text-shadow: none;
  letter-spacing: 0.06em;
}
.tablesaw thead tr:first-child th {
  padding-top: 1.1em;
  padding-bottom: 0.9em;
}
.tablesaw thead tr:first-child th {
  font-weight: 600;
  font-family: inherit;
  border: none;
}
.tablesaw td,
.tablesaw tbody th {
  font-size: inherit;
  line-height: inherit;
  padding: 10px !important;
}
.tablesaw-stack tbody tr,
.tablesaw tbody tr {
  border-bottom: none;
}
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after,
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
  font-family: FontAwesome;
  font-size: 10px;
}
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after {
  content: "\f176";
}
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
  content: "\f175";
}
.tablesaw-bar .btn-select.btn-small:after,
.tablesaw-bar .btn-select.btn-micro:after {
  font-size: 8px;
  padding-right: 10px;
}
.tablesaw-swipe .tablesaw-cell-persist {
  box-shadow: none;
}
.tablesaw-enhanced .tablesaw-bar .btn {
  text-shadow: none;
  background-image: none;
}
.tablesaw-enhanced .tablesaw-bar .btn.btn-select:hover {
  background: #ffffff;
}
.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus,
.tablesaw-enhanced .tablesaw-bar .btn:active {
  color: #3bafda !important;
  background-color: #f5f5f5;
  outline: none !important;
  box-shadow: none !important;
  background-image: none;
}
/* =============
     Form elements
  ============= */
.error {
  color: #ef5350;
  font-size: 12px;
  font-weight: 500;
}
.parsley-error {
  border-color: #ef5350 !important;
}
.parsley-errors-list {
  display: none;
  margin: 0;
  padding: 0;
}
.parsley-errors-list.filled {
  display: block;
}
.parsley-errors-list > li {
  font-size: 12px;
  list-style: none;
  color: #f6504d;
}
/* Datepicker */
.datepicker {
  padding: 8px;
}
.datepicker th {
  font-size: 14px !important;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-image: none;
}
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #3bafda;
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-color: #3bafda !important;
  background-image: none;
  box-shadow: none;
  text-shadow: none;
}
.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr th:hover {
  background-color: #fafafa;
}
.datepicker-inline {
  border: 2px solid #eeeeee;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #3bafda;
  border-color: #3bafda;
}
.daterangepicker .input-mini.active {
  border: 1px solid #AAAAAA;
}
.daterangepicker .ranges li {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  background-clip: padding-box;
  color: #797979;
  font-weight: 600;
  font-size: 12px;
}
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  border: 1px solid #e3e3e3;
  padding: 2px;
  width: 60px;
}
.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
  background-color: #3bafda;
  border: 1px solid #3bafda;
}
.search-input {
  margin-bottom: 10px;
}
.ms-selectable {
  box-shadow: none;
  outline: none !important;
}
.ms-container .ms-list.ms-focus {
  box-shadow: none;
}
.ms-container .ms-selectable li.ms-hover {
  background-color: #3bafda;
}
.ms-container .ms-selection li.ms-hover {
  background-color: #3bafda;
}
.note-editor {
  border: 1px solid #eeeeee;
  position: relative;
}
.note-editor .note-toolbar {
  background-color: rgba(152, 166, 173, 0.1);
  border-bottom: 1px solid #eeeeee;
  margin: 0;
}
.note-editor .note-statusbar {
  background-color: #ffffff;
}
.note-editor .note-statusbar .note-resizebar {
  border-top: none;
  height: 15px;
  padding-top: 3px;
}
.note-popover .popover .popover-content {
  padding: 5px 0 10px 5px;
}
.note-toolbar {
  padding: 5px 0 10px 5px;
}
/* Timepicker */
.bootstrap-timepicker-widget table td a:hover {
  background-color: transparent;
  border-color: transparent;
  border-radius: 4px;
  color: #3bafda;
  text-decoration: none;
}
.editor-horizontal .popover-content {
  padding: 9px 30px;
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-color: #3bafda !important;
  background-image: none;
  box-shadow: none;
}
/* Dropzone */
.dropzone {
  min-height: 230px;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 6px;
}
.dropzone .dz-message {
  font-size: 30px;
}
/* ===========
   Calendar
 =============*/
.calendar {
  float: left;
  margin-bottom: 0px;
}
.none-border .modal-footer {
  border-top: none;
}
.fc-toolbar {
  margin-bottom: 5px;
}
.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-transform: uppercase;
}
.fc-day {
  background: #ffffff;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}
.fc-widget-header {
  border: 1px solid #d5d5d5;
}
.fc-widget-content {
  border: 1px solid #d5d5d5;
}
.fc th.fc-widget-header {
  background: #dddddd;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0px;
  text-transform: uppercase;
}
.fc-button {
  background: #ffffff;
  border: 1px solid #d5d5d5;
  color: #555555;
  text-transform: capitalize;
}
.fc-text-arrow {
  font-family: arial;
  font-size: 16px;
}
.fc-state-hover {
  background: #F5F5F5;
}
.fc-state-highlight {
  background: #f0f0f0;
}
.fc-cell-overlay {
  background: #f0f0f0;
}
.fc-unthemed .fc-today {
  background: #ffffff;
}
.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}
.external-event {
  color: #ffffff;
  cursor: move;
  margin: 10px 0;
  padding: 6px 10px;
}
.fc-basic-view td.fc-week-number span {
  padding-right: 5px;
}
.fc-basic-view td.fc-day-number {
  padding-right: 5px;
}
/* ===========
   Widgets
 =============*/
.inbox-widget{
  outline: none;
  border-top: 1px solid var(--dash);
}

.inbox-widget .inbox-item {
  border-bottom: 1px solid #f1f1f1;
  overflow: hidden;
  padding: 10px 0;
  position: relative;
}
.inbox-widget .inbox-item .inbox-item-img {
  display: block;
  float: left;
  margin-right: 15px;
  width: 40px;
  min-height: 50px;
}

.inbox-item-img ::before{
  margin-top: 0px !important;
}

.inbox-widget .inbox-item img {
  width: 40px;
}
.inbox-widget .inbox-item .inbox-item-author {
  color: #333333;
  display: block;
  margin: 0;
  font-weight: bold;
}
.inbox-widget .inbox-item .inbox-item-text {
  color: #555252;
  display: block;
  font-weight: 600;
  /* font-size: 12px; */
  margin: 0;
}
.inbox-widget .inbox-item .inbox-item-date {
  color: #a9a9a9;
  font-size: 11px;
  position: absolute;
  right: 7px;
  top: 2px;
}
.conversation-list {
  list-style: none;
  max-height: 330px;
  padding: 0px 20px;
}
.conversation-list li {
  margin-bottom: 24px;
}
.conversation-list .chat-avatar {
  display: inline-block;
  float: left;
  text-align: center;
  width: 40px;
}
.conversation-list .chat-avatar img {
  border-radius: 100%;
  width: 100%;
}
.conversation-list .chat-avatar i {
  font-size: 12px;
  font-style: normal;
}
.conversation-list .ctext-wrap {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background: #f5f5f5;
  border-radius: 3px;
  display: inline-block;
  padding: 10px;
  position: relative;
}
.conversation-list .ctext-wrap i {
  color: #1a2942;
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  position: relative;
}
.conversation-list .ctext-wrap p {
  margin: 0px;
  padding-top: 3px;
}
.conversation-list .ctext-wrap:after {
  right: 100%;
  top: 20%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(213, 242, 239, 0);
  border-right-color: #f5f5f5;
  border-width: 5px;
  margin-top: -5px;
}
.conversation-list .conversation-text {
  display: inline-block;
  float: left;
  font-size: 12px;
  margin-left: 12px;
  width: 70%;
}
.conversation-list .odd .chat-avatar {
  float: right !important;
}
.conversation-list .odd .conversation-text {
  float: right !important;
  margin-right: 12px;
  text-align: right;
  width: 70% !important;
}
.conversation-list .odd .ctext-wrap {
  background: #e8e9ec !important;
}
.conversation-list .odd .ctext-wrap i {
  color: #acacac;
}
.conversation-list .odd .ctext-wrap:after {
  border-color: rgba(238, 238, 242, 0) !important;
  border-left-color: #e8e9ec !important;
  left: 100% !important;
  top: 20% !important;
}
.chat-send {
  padding-left: 0px;
  padding-right: 30px;
}
.chat-send button {
  width: 100%;
}
.chat-inputbar {
  padding-left: 30px;
}
#todo-message {
  font-size: 16px;
}
.todo-list li {
  border: 0px;
  margin: 0px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  background: transparent !important;
}
.todo-list li:last-of-type {
  border-bottom: none;
}
.todo-send {
  padding-left: 0px;
}
/* Widget-chart */
.widget-chart ul li {
  width: 31.5%;
  display: inline-block;
  padding: 0px;
}
.widget-panel {
  padding: 30px 20px;
  padding-left: 30px;
  border-radius: 4px;
  position: relative;
  margin-bottom: 20px;
}
.widget-panel i {
  font-size: 60px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  right: 0px;
  bottom: 0px;
  top: 0px;
  line-height: 60px;
}
.widget-user {
  min-height: 112px;
}
.widget-user img {
  height: 72px;
  float: left;
}
.widget-user .wid-u-info {
  margin-left: 90px;
}
.widget-user .wid-u-info p {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget-simple-chart .circliful-chart {
  float: left;
  margin-top: -5px;
}
.widget-icon i {
  float: left;
  font-size: 48px;
}
.widget-icon .wid-icon-info {
  margin-left: 80px;
}

.swal2-confirm{
 margin-right: 10px;
}
.swal2-popup .swal2-actions{
  margin: 1.25em auto 0.7em !important;
}



/***** PopOver ***/

.popover {
  position: absolute;
  top: -69px;
  left: -32px;
  z-index: 9999999999999999999;
  display: block;
  max-width: 276px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 12px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: .3rem;
  width: 1000px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.02);
  padding: 5px 15px;
}

.popover-lab .popover-body{
 color: #07a3e1;
}

.popover-clinic .popover-body{
 color:#003082;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem;
}

.bs-popover-auto, .bs-popover-top {
  margin-bottom: 9px;
}

.bs-popover-auto .arrow, .bs-popover-top .arrow {
  bottom: -17px;
}

.bs-popover-bottom .arrow, .bs-popover-top .arrow {
  left: 50%;
  margin-left: -14px !important;
}


.popover .arrow::after, .popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-auto .arrow::before, .bs-popover-top .arrow::before {
  bottom: 0;
  border-top-color: rgba(0,0,0,.25);
}

.bs-popover-auto .arrow::after, .bs-popover-top .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-auto .arrow::after, .bs-popover-auto .arrow::before, .bs-popover-top .arrow::after, .bs-popover-top .arrow::before {
  border-width: .5rem .5rem 0;
}


.bs-popover-auto, .bs-popover-top {
  margin-bottom: 9px;
}



.popover-body {
  padding: .5rem .75rem;
  color: #212529;
}

.btn-clinic{
  background:url(../images/icons/filtro_clinica_o.svg);
  border: none;
  border-radius: 25px;
  width: 30px;
  height: 30px;
  float: right;
  background-size: cover;
}

.active-work-item-clinic{
  background: url(../images/icons/filtro_clinica.svg);
  background-size: cover;
}


.btn-lab{
  background:url(../images/icons/filtro_laboratorio_o.svg);
  border: none;
  border-radius: 25px;
  width: 30px;
  height: 30px;
  float: right;
  background-size: cover;
}

.active-work-item-lab{
  background: url(../images/icons/filtro_laboratorio.svg);
  background-size: cover;
}

h5.clinic-nm i.clickable{
  background: url(../images/icons/filtro_clinica_white.svg);
}

h5.lab-nm i.clickable, h5.lab-nm2 i.clickable{
  background: url(../images/icons/filtro_laboratorio_white.svg);
}

.active-avatar, .pending-avatar, .closed-avatar{
  width: 45px;
  height: 45px;
}

.avatar-lg{
  width: 60px;
  height:60px;
}


.c-work-state-active-border .work-avatar, .selected-active-work-item .work-avatar, .active-avatar {
  background: url(../images/icons/trabajo_imagen_generica_active.svg);
  background-size: cover;
}

.c-work-state-pending-border .work-avatar, .selected-pending-work-item .work-avatar, .pending-avatar{
  background: url(../images/icons/trabajo_imagen_generica_pending.svg);
  background-size: cover;
}

.c-work-state-decline-border .work-avatar, .selected-decline-work-item .work-avatar, .closed-avatar{
  background: url(../images/icons/trabajo_imagen_generica_closed.svg);
  background-size: cover;
}

.c-work-state-finalized-border .work-avatar, .selected-finalized-work-item .work-avatar, .finalized-avatar{
  background: url(../images/icons/trabajo_imagen_generica_closed.svg);
  background-size: cover;
}
